import React from 'react';
import { connect } from 'react-redux';
import DownloadAppButtons from './DownloadAppButtons';
import { StaticQuery, graphql } from 'gatsby';

import Style from '../styles/components/footer.module.scss'

const PreFooter = (props) => (
  <StaticQuery
    query={graphql`
      query {
        prefooter: allPrismicSectionPrefooter {
          edges {
            ...preFooter
          }
        }
        appleButton: allFile(filter: {relativePath: {regex: "/content_applestorebutton/"}}) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        playstoreButton: allFile(filter: {relativePath: {regex: "/_content_googleplaybutton/"}}) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        footerBackground: allFile(filter: {relativePath: {eq: "wakatoonbaseline.png"}}) {
          edges {
            node {
              publicURL
            }
          }
        }
        footerBackgroundTransparent: allFile(filter: {relativePath: {eq: "persos.png"}}) {
          edges {
            node {
              publicURL
            }
          }
        }
        footerBackgroundFlatMobile: allFile(filter: {relativePath: {eq: "pre-footer-background-flat-1.png"}}) {
          edges {
            node {
              publicURL
            }
          }
        }
      }
    `}
    render={(data) => {
      const preFooter = data.prefooter.edges.length > 1
        ? data.prefooter.edges.filter((edge) => edge.node.lang === props.locale)[0].node.data
        : data.prefooter.edges[0].node.data;
      
      return (
        <div>
          <section
            id="download"
            className={`${Style.sectionPreFooter}`}
            style={props.isDownloadPage ?
              {
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                paddingBottom: '100px',
                backgroundColor: 'white',
                backgroundImage: `url(${data.footerBackgroundTransparent.edges[0].node.publicURL})`
              } :
              {
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundImage: (props.viewportSize === 'large' || props.viewportSize === 'medium')
                  ? `url(${data.footerBackground.edges[0].node.publicURL})`
                  : `url(${data.footerBackgroundFlatMobile.edges[0].node.publicURL})`
              }
            }
          >
            <h2>{preFooter.title.text}</h2>
            <p>{preFooter.subtitle.text}</p>
            <DownloadAppButtons {...{ buttons: preFooter.buttons}} localButtons={{apple: data.appleButton, playstore: data.playstoreButton}} />
          </section>
        </div>
      )
    }}
  />
)

const mapStateToProps = (state) => ({
  ...state.viewport
});
export default connect(mapStateToProps)(PreFooter);