import React from 'react'
import Style from '../../styles/components/banner.press.module.scss'
import Image from '../Images'

const Cols = (props) => {
  return (
    <div className="col-4">
      { props.cols.map((item, index) => {
        return <Item key={`item-${index}`} {...{ item: item, index: index }} />
      })}
    </div>
  );
}

const Item = (props) => {
  return (
    <div className={`${Style.row} row`}>
      <div className={`${Style.col12} col-12`}>
        <Image 
          className={props.item.format === 'regular' ? Style.regular : Style.square}
          src={props.item.logo.url} 
          alt={props.item.name.text}
        />
        {/* <img
          className={ props.item.format === 'regular' ? Style.regular : Style.square }
          src={props.item.logo.url} alt={props.item.name.text}
        /> */}
      </div>
    </div>
  );
}

export default (props) => {
  return (
    <div className={Style.items}>
      <div className={`${Style.wrapper} ${Style.row} row`}>
        {props.blocks.map((cols, index) => {
          return <Cols key={`col-${index}`} {...{ cols: cols, index: index }} />
        })}
      </div>
    </div>
  );
}