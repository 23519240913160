import React from 'react'
import Style from '../styles/components/products.module.scss'

const handleWakatoonShopify = () => {
  if (typeof window !== 'undefined') {
    window.open('https://shop.wakatoon.com/');
  }
}

export const ActionProductsListing = (props) => {
  return (
    <div>
      {
        props.unset ? (
          <button
            id={`product-item-${props.product.projectId}`}
            className={`${Style.buy} ${Style.btnOpacity} button square btn btn_default Product-btn-opacity"`}
            onClick={() => false }
          ><i className={`w-icons icon-${props.product.type === 'book' ? 'cart' : 'print'}`}></i></button>
        ) : (
          <React.Fragment>
            {
              props.disabled ? (
                <button
                  id={`product-item-${props.product.projectId}`}
                  className={`${Style.buy} button square btn btn_default`}
                  onClick={() => props.handlePrintable(props.product)}
                >
                <i className="w-icons icon-print"></i></button>
              ) : (
                <button
                  id={`product-item-${props.product.projectId}`}
                  className={`${Style.buy} button square btn btn_default`}
                  onClick={
                    props.product.type === 'book'
                      // ? () => props.addVariantToCart(props.product.id, 1)
                      ? () => handleWakatoonShopify()
                      : () => props.handlePrintable(props.product)
                  }
                >
                <i className={`w-icons icon-${props.product.type === 'book' ? 'cart' : 'print'}`}></i></button>
              )
            }
          </React.Fragment>
        )
      }
    </div>
  );
}

export const ActionProductsTesting = (props) => {
  return (
    <div>
      <button
        id={`product-item-${props.product.projectId}`}
        className={`${Style.buy} button square btn btn_default`}
        onClick={() => props.handlePrintable(props.product)}
      >
        <i className="w-icons icon-print"></i>
      </button>
    </div>
  );
}

export const ActionHomePageBooks = (props) => {
  const _t = (str) => props._t[props._t.findIndex(item => item.key_text === str)].text.text;
  return (
    <div>
      {
        props.lang !== 'en' && (
          <button
            className="button btn btn_default"
            // onClick={() => props.addVariantToCart(props.product.id, 1)}
            onClick={() => handleWakatoonShopify()}
          >
            {_t('Acheter')}
          </button>
        )
      }
      {
        props.product.isPrintable && 
        <button
          onClick={() => props.handlePrintable(props.product)}
          className="button btn btn_default"
        >
          {_t('Tester')}
        </button>
      }
    </div>
  );
}

export const ActionStory = (props) => {
  const _t = (str) => props._t[props._t.findIndex(item => item.key_text === str)].text.text;
  return (
    <div>
      {
        props.lang !== 'en' && (
          <button
            className={`${Style.buy} button btn btn_default`}
            onClick={
              props.product.type === 'book'
                // ? () => props.addVariantToCart(props.product.id, 1)
                ? () => handleWakatoonShopify()
                : () => props.handlePrintable(props.product)
            }
          ><i className={`w-icons icon-${props.product.type === 'book' ? 'cart' : 'print'}`}></i></button>
        )
      }
      {
        props.product.isPrintable && 
        <button
          onClick={() => props.handlePrintable(props.product)}
          className="button btn btn_default"
        >
          {_t('Tester')}
        </button>
      }
    </div>
  );
};