/**
 * @todo Refactoring
 * @todo Format props & add a logic on Products component to manage all products sources.
 * @todo Replace render method code by an import of Products component.
 */

import React from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import Image from '../Images'
import Slider from 'react-slick'
import Truncate from 'react-truncate'
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'
import ProductAction from '../../actions/ProductAction'
import ShopButton from '../ShopButton'
import { AgeBadge } from '../Badges'
import Style from '../../styles/components/banner.books.module.scss'

const Books = (props) => {
  const _t = (str) => props._t[props._t.findIndex(item => item.key_text === str)].text.text;
  const _data = props.edges;
  const data = _data.length > 0 ? _data[0].node.data : [];
  if (data.length === 0) {
    return <div></div>
  }
  
  const bannerItemsCountLimit = 12;
  const filtered = data.stories.filter((item, index) => {
    return (
      item.story 
      && (item.story.document[0].uid !== props.context.uid) 
      && index < bannerItemsCountLimit 
      && JSON.parse(item.story.document[0].data.home_featured)
    )
  });
  
  const items = filtered.map((item) => {
    // let product = item.story.document[0].data.shopify_item.variants[0];
    let product = {};
    // product.id = Buffer.from(product.admin_graphql_api_id.toString()).toString('base64');
    product.projectId = item.story.document[0].data.project_id;
    product.name = item.story.document[0].data.title.text;
    product.type = item.story.document[0].data.type;
    product.isPhysical = JSON.parse(item.story.document[0].data.physical_book);
    product.isPrintable = JSON.parse(item.story.document[0].data.printable);
    product.isBook = JSON.parse(item.story.document[0].data.physical_book);
    product.inAppPurchase = JSON.parse(item.story.document[0].data.in_app_purchase);
    product.popupBackground = item.story.document[0].data.popup_background;
    
    return {
      uid: item.story.document[0].uid,
      project_id: item.story.document[0].data.project_id,
      type: item.story.document[0].data.type,
      title: item.story.document[0].data.title.text,
      short_title: item.story.document[0].data.short_title.text,
      title_part_1: item.story.document[0].data.title_part_1.text,
      title_part_2: item.story.document[0].data.title_part_2.text,
      description: item.story.document[0].data.description.text,
      age: item.story.document[0].data.age.text,
      image: item.story.document[0].data.image.url,
      product: product
    }
  });
  const bannerCustomSettings = (() => {
    switch (props.viewportSize) {
      case 'small':
        return { slides: 1, dots: true }
      case 'medium':
        return { slides: 2, dots: true }
      case 'small-height':
        return { slides: 2, dots: true }
      default :
        return { slides: 3, dots: false }
    }
  })();
  
  const settings = {
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    infinite: false,
    speed: 500,
    slidesToShow: bannerCustomSettings.slides,
    slidesToScroll: bannerCustomSettings.slides,
    dots: bannerCustomSettings.dots
  };
  
  return (
    <section className="Books" style={{background:"#fff"}}>
      <h2 style={{ display: `${props.thumbnailsDisplay ? 'none' : 'block'}` }}>
        {props.title || data.title.text}
      </h2>
      <Slider {...settings} className={Style.slider}>
        {items.map((item, index) => {
          return (
            <div key={`product_${index}`} className={Style.items}>
              <div className={Style.imageWrapper}>
                {(item.age && typeof props.thumbnailsDisplay === 'undefined') &&
                  <AgeBadge text={item.age} class="products-banner" />
                }
                <Link to={`${props.shopUrl}${item.project_id}/${item.uid}/`}>
                  <Image src={item.image} alt={item.title} />
                </Link>
              </div>
              <div className={Style.titleWrapper} style={{ display: `${props.thumbnailsDisplay ? 'none' : 'block'}` }}>
                <h3>
                  <Link to={`${props.shopUrl}${item.project_id}/${item.uid}/`}>
                    {item.title}
                  </Link>
                </h3>
                <p>
                  <Truncate lines={2}>{item.description}</Truncate>
                  <span>
                    <Link to={`${props.shopUrl}${item.project_id}/${item.uid}/`}>
                      <span>{_t('En savoir +')}</span>
                    </Link>
                  </span>
                  <small>
                    {!item.title_part_2 && <span>&nbsp;</span>}
                  </small>
                </p>
              </div>
              {
                !props.title && (
                  <div className={Style.buttons} style={{ display: `${props.thumbnailsDisplay ? 'none' : 'block'}` }}>
                    <ProductAction 
                      page="index" 
                      product={item.product} 
                      context={props.context}
                      _t={props._t}
                    />
                  </div>
                )
              }
            </div>
          )
        })}
      </Slider>
      <ShopButton shopUrl={props.shopUrl} label={_t('La boutique Wakatoon')} />
    </section>
  );
}

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(Books);