import React from 'react'
// import { Link } from 'gatsby'

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 30
}

export default (props) => {
  // const type = props.type || 'relative';
  return (
    <div style={buttonStyle}>
      {/* {
        type === 'relative' ? (
          <Link 
            className={`button btn btn_default ${props.isBlue ? 'btn_blue' : ''}`} 
            to={`${props.shopUrl}`}
          >
            {props.label}
          </Link>
        ) : (
          <a
            href={`${props.shopUrl}`}
            className={`button btn btn_default ${props.isBlue ? 'btn_blue' : ''}`} 
          >
            {props.label}
          </a>
        )
      } */}
      <a
        href="https://shop.wakatoon.com/"
        className={`button btn btn_default ${props.isBlue ? 'btn_blue' : ''}`} 
      >
        {props.label}
      </a>
    </div>
  )
}