import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'
import { Link } from 'gatsby';
import Style from '../../styles/components/banner.blogposts.module.scss'
import Image from '../../components/Images'

const BlogPosts = (props) => {
  const items = props.edges.filter((i,index) => {
    if (props.postId) {
      return i.node.uid !== props.postId;
    } else {
      return index < 3;
    }
  });
  const dots = props.viewportSize !== 'large';
  const slides = props.viewportSize !== 'large' ? 1 : 3;
  const colorConversion = {
    '#8c86d1': '_purple',
    '#ef6c85': '_pink',
    '#5fc090': '_green',
    '#00abe7': '_blue',
    '#f2bd12': '_yellow',
  };
  const settings = {
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: slides,
    dots: dots
  };
  return (
    <section className="Wakastuces">
      <div className={Style.section}>
        <h2>{props.post ? 'Nos autres articles' : 'Les derniers articles'}</h2>
        <Slider {...settings}>
          { items.map((item, index) => {
            return (
              <div className={`${Style.posts} ${item.node.data.category.uid}`} key={`post-${index}`}>
                <div className={`${Style.postContainer}`}>
                  <div>
                    {
                      item.node.data.thumbnail ? (
                        <Image src={item.node.data.thumbnail.url} alt={item.node.data.meta_title} />
                      ) : (
                        <Image src={item.node.data.header.url} alt={item.node.data.meta_title} />
                      )
                    }
                  </div>
                  <div className={Style.contents}>
                    <span style={{ color: `${item.node.data.category.document[0].data.color}`}}>
                      {item.node.data.category.document[0].data.category_name}
                    </span>
                    <h3>{item.node.data.title.text}</h3>
                    <p>
                      {item.node.data.excerpt}
                    </p>
                  </div>
                  {}
                  <Link 
                    key={`blog-category-${index}`} 
                    className={`button btn btn_default ${colorConversion[item.node.data.category.document[0].data.color]}`}
                    to={`/fr/blog/${item.node.data.category.uid}/${item.node.uid}/`}
                  >
                    Lire la suite
                  </Link>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(BlogPosts);