import React from 'react'
import ProductAction from '../../actions/ProductAction'
import Style from '../../styles/components/printableTests.module.scss'
import Image from '../Images'

const PrintableTests = (props) => {

  const _t = (str) => props._t[props._t.findIndex(item => item.key_text === str)].text.text;

  const filtered = props.stories.filter((item) => {
    return item.story !== null
  });
  const stories = filtered.map((item) => {
    return {
      projectId: item.story.document[0].data.project_id,
      name: item.story.document[0].data.title.text,
      image: item.story.document[0].data.test_image.url,
      alt_image: item.story.document[0].data.test_alt_attribute,
      type: item.story.document[0].data.type,
      isBook: item.story.document[0].data.physical_book,
      isprintable: item.story.document[0].data.printable,
      inAppPurchase: item.story.document[0].data.in_app_purchase,
      popupBackground: item.story.document[0].data.popup_background,
      pageTracking: 'demo'
    }
  });
  
  return (
    <section className={Style.section} style={{background: `${props.regular ? 'transparent' : '#f3f3f3'}`}}>
      <div>
        <h2>{_t('Vous souhaitez tester ?')}</h2>
        <h3 className="blue">{_t(`C'est possible gratuitement !`)}</h3>
        <div dangerouslySetInnerHTML={{
          __html: _t(`Imprimez votre coloriage de test et le transformer en dessin animé :`, true)
        }}></div>
      </div>
      <div className={Style.tests}>
        {stories.map((item, index) => {
          return (
            <div className={Style.containers} key={`test-${index}`}>
              <Image 
                style={{ cursor: 'pointer' }}
                src={item.image}
                alt={item.alt_image} 
                onClick={() => document.getElementById(`product-item-${item.projectId}`).click()}
              />
              <h3>{item.name}</h3>
              <ProductAction 
                page="testing" 
                product={item} 
                context={props.context} 
                _t={props._t}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default PrintableTests;