import React from 'react'
import { connect } from 'react-redux'
import Blocks from './PressReleasesBlocks'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'

import Style from '../../styles/components/banner.press.module.scss'

const PressReleases = (props) => {
  
  const edges = props.edges;
  const data = edges.length > 1 
    ? edges.filter((edge) => edge.node.lang === props.locale)[0].node.data
    : edges[0].node.data;
    
  let columns = []
    , regular = 0
    , square = 0
    , columnCount = 0
    , columnId = 0;

  columns[columnId] = [];

  const items = { 
    regular: data.items.filter((item) => {
      if (item.format === 'regular' && JSON.parse(item.home_featured) === true) {
        if (columns[columnId][columnCount] === undefined) {
          columns[columnId][columnCount] = [];
        }
        
        columns[columnId][columnCount][regular] = item; ++regular;
        if (columns[columnId][columnCount].length === 3) {
          ++columnCount; 
          if (columnCount === 3) {
            ++columnId; columns[columnId] = [];
          } regular = 0;
        }
        return {
          ...item
        }
      }
      return false;
    }), 
    square: data.items.filter((item) => {
      if (item.format === 'square' && JSON.parse(item.home_featured) === true) {
        if (columns[columnId][columnCount] === undefined) {
          columns[columnId][columnCount] = [];
        }

        columns[columnId][columnCount][square] = item; ++square;
        if (columns[columnId][columnCount].length === 2) {
          ++columnCount;
          if (columnCount === 3) {
            ++columnId; columns[columnId] = [];
          } square = 0;
        }
        return {
          ...item
        }
      }
      return false;
    }),
    blocks: columns.filter((column) => column.length > 0 && column)
  }

  const dots = props.viewportSize !== 'large';

  const settings = {
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: dots
  };

  return (
    <section className="PressReleases">
      <h2>{data.title.text}</h2>
      <Slider {...settings} className={Style.slider}>
        {items.blocks.map((blocks, index) => {
          return <Blocks key={`pane-${index}`} {...{blocks: blocks, index: index}} />
        })}
      </Slider>
    </section>
  )
};

const mapStateToProps = (state) => ({
  ...state.locale, ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(PressReleases);