import React from 'react'
import Style from '../styles/components/concept.module.scss'

const Concept = (props) => {
  const data = props.edges[0].node.data;
  const videoId = data.video.embed_url.split('?v=');
  const bgLeft = props.background.left.edges[0].node.publicURL;
  const bgRight = props.background.right.edges[0].node.publicURL;
  // console.log(props);
  return (
    <section className={Style.section}>
      <div style={{ background: `url(${bgLeft}) center left -170px no-repeat, url(${bgRight}) center right -150px no-repeat` }}>
        <h2><span>{data.title.text} :</span> {data.subtitle.text}</h2>
        <div>
          <iframe
            title={`Wakatoon: ${data.title.text}`}
            width="480"
            height="270"
            src={`https://www.youtube.com/embed/${videoId[1]}?feature=oembed`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </section>
  );
};

export default Concept;