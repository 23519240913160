import React from 'react'
import { connect } from 'react-redux'
import PrintableTests from './Banners/PrintableTests'
import Image from './Images'
import Style from '../styles/components/howitworks.module.scss'
import ShopButton from '../components/ShopButton'

const HowItWorks = (props) => {

  const homepage = { homepage: props.homepage !== undefined };
  const data = props.edges[0].node.data;
  const stepsImages = data.featured_story.document[0].data;
  const stepsTitles = data.steps.document[0].data.steps;
  let steps = [];

  for (let i = 0; i < 3; ++i) {
    steps.push({
      'stepNumber': i + 1,
      'title': stepsTitles[i].title.text,
      'alt_title': props.steps ? props.steps[i].alt : stepsTitles[i].alt_title.text,
      'description': stepsTitles[i].description.text,
      'imageUrl': props.steps ? props.steps[i].url : stepsImages[`step_${i+1}`].url,
      'videoUrl': stepsImages[`step_${i+1}_animation`].url
    });
  }
  
  const HowItWorksBlock = (props) => {
    return (
      <React.Fragment>
        <h2 
          className={!props.homepage ? 'blue' : ''}
          style={{ display: `${props.collapse ? 'none' : 'block'}`}}
        >
          {data.title.text}
        </h2>
        <div className={Style.steps}>
          { props.steps.map((step) => {
            return (
              <div key={step.stepNumber}>
                <div className={`${Style.containers}`}>
                  <Image src={step.imageUrl} alt={step.description} transparent={true} />
                  <span className={Style.number}>{step.stepNumber}</span>
                </div>
                <h3>{ props.homepage ? step.title : step.alt_title }</h3>
                { !props.homepage && <p>{ step.description }</p> }
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
  
  if (homepage.homepage) {
    return (
      <React.Fragment>
        { props.collapse ? (
          <HowItWorksBlock 
            collapse={props.collapse} 
            twic={props.twic} 
            viewport={props.viewportSize} 
            steps={steps} 
            {...homepage} 
            _t={props._t}
          />
        ) : (
          <section className={`${Style.section} howitworks`}>
            <HowItWorksBlock 
              collapse={props.collapse} 
              twic={props.twic} 
              viewport={props.viewportSize} 
              steps={steps} 
              {...homepage}
              _t={props._t}
            />
          </section>
        )}
      </React.Fragment>
    )
  }
  
  return (
    <div>
      <section className={`${Style.section} ${!props.homepage ? 'regular' : ''}`}>
        <div className="page-head no-custom-style">
          <h1>{props.pageData.page_title.text}</h1>
          {
            data.intro_title && (
              <React.Fragment>
                <h2>{data.intro_title.text}</h2>
                <div
                  className="page-header-text"
                  dangerouslySetInnerHTML={{
                    __html: data.intro_text.html,
                  }}
                ></div>
              </React.Fragment>
            )
          }
          <HowItWorksBlock 
            twic={props.twic} 
            steps={steps} 
            {...homepage} 
            _t={props._t}
          />
        </div>
      </section>
      <section className={`${Style.section} ${!props.homepage ? 'regular' : ''}`}>
        {
          (data.body && data.body.length) > 0 && (
            <div className="page-head no-custom-style">
              {data.body.map((item, index) => {
                return (
                  <React.Fragment key={`hiw-content-${index}`}>
                    <h2>{item.primary.content_title.text}</h2>
                    <div
                      className="page-header-text"
                      dangerouslySetInnerHTML={{
                        __html: item.primary.content.html,
                      }}
                    ></div>
                    {
                      item.primary.cta_label && (
                        <div style={{marginBottom: '5rem'}}>
                          <ShopButton
                            shopUrl={item.primary.cta_link.url}
                            label={item.primary.cta_label}
                            type="absolute"
                          />
                        </div>
                      )
                    }
                  </React.Fragment>
                )
              })}
            </div>
          )
        }
      </section>
      <section>
        <h2 className="blue">{props.pageData.page_subtitle.text}</h2>
        <div className={Style.centered}>
          <Image 
            src={props.pageData.header.url} 
            force={true} 
            alt={props.pageData.image_alt_attribute}
            transparent={true}
          />
        </div>
      </section>
      <PrintableTests 
        stories={data.stories} 
        context={props.context} 
        _t={props._t}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state.viewport
});

export default connect(mapStateToProps)(HowItWorks);