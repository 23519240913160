import React from 'react'
import Style from '../styles/components/downloadApp.module.scss'

const DownloadAppButtons = (props) => {

  const buttons = {
    applestore: props.buttons !== null 
      ? props.buttons.document[0].data.apple.document[0].data 
      : props.buttons,
    googleplay: props.buttons !== null 
      ? props.buttons.document[0].data.googleplay.document[0].data 
      : props.buttons
  };

  const appleButtonNameArray = buttons.applestore.image.url.split('/');
  const appleButtonName = appleButtonNameArray[appleButtonNameArray.length - 1]
  .replace('.png?auto=compress,format', '')
  .replace('wakatoon%2F', '');
  
  const appleButtonLocalFile = props.localButtons.apple.edges.find((i) => {
    return i.node.name === appleButtonName;
  })

  const playstoreButtonNameArray = buttons.googleplay.image.url.split('/');
  const playstoreButtonName = playstoreButtonNameArray[appleButtonNameArray.length - 1]
  .replace('.png?auto=compress,format', '')
  .replace('wakatoon%2F', '');
  
  const playstoreButtonLocalFile = props.localButtons.playstore.edges.find((i) => {
    return i.node.name === playstoreButtonName;
  })
  
  if (buttons.applestore === null || buttons.googleplay === null) {
    return <div></div>
  } else {
    return (
      <div className={Style.container}>
        <a 
          target="_blank" 
          rel="noopener noreferrer" 
          href={buttons.applestore.link} 
          title={buttons.applestore.alt_text.text}
          className={Style.buttons}
        >
          <img 
            src={appleButtonLocalFile.node.publicURL} 
            alt={buttons.applestore.image_alt_attribute}
          />
        </a>
        <a 
          target="_blank" 
          rel="noopener noreferrer" 
          href={buttons.googleplay.link} 
          title={buttons.googleplay.alt_text.text}
          className={Style.buttons}
        >
          <img 
            src={playstoreButtonLocalFile.node.publicURL}  
            alt={buttons.googleplay.image_alt_attribute}
          />
        </a>
      </div>
    )
  }
}

export default DownloadAppButtons;