import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'
import Image from '../Images'
import Style from '../../styles/components/banner.testimonials.module.scss'

const Testimonials = (props) => {
  
  const data = props.edges[0].node.data;
  const items = data.testimonials.document[0].data.testimonials;

  const slides = props.viewportSize !== 'large' ? 1 : 2;
  const dots = props.viewportSize !== 'large';

  const settings = {
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: slides,
    dots: dots
  };

  return (
    <section className={`${Style.section} Testimonials`}>
      <Image 
        src={`${data.background_left.url}`} 
        alt="background left"
        className={`${Style.customBorders}`} 
        transparent
        // regular
      />
      <Image 
        src={`${data.background_right.url}`} 
        alt="background left"
        className={`${Style.customBorders}`} 
        transparent
        // regular
      />
      <h2>{data.title.text}</h2>

      <Slider {...settings} className={Style.slider}>
        {items.map((item, index) => {
          return JSON.parse(item.home_page_featured) === true &&
            (
              <div key={`testimonial_${index}`} className={Style.items}>
                <div className={Style.wrapper}>
                  <p>{item.description.text}</p>
                  <span>
                    <strong>{item.author.text}</strong>
                    {item.subtitle.text !== null && `, ${item.subtitle.text}`}
                  </span>
                </div>
              </div>
            )
        })}
      </Slider>
    </section>
  );
}

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(Testimonials);