import React from 'react'

export const AgeBadge = (props) => {
  const badge = props.text.split(' ');
  return (
    <div className={`badges badge-age ${props.class !== undefined ? props.class : ''}`}>
      {`${badge[0]}-${badge[2]}`}
      <small>{badge[3]}</small>
    </div>
  )
};