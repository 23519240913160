/**
 * @todo refactoring
 */
import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'
import Image from '../Images'
import Style from '../../styles/components/banner.home.module.scss'

class HomeBanner extends React.Component {

  render() {
    const localizedContent = this.props.edges[0].node;
    const items = localizedContent.data.banners;
    const stamp = localizedContent.data.stamp;
    const settings = {
      prevArrow: <CustomPrevArrow type="banner" />,
      nextArrow: <CustomNextArrow type="banner" />,
      autoplay: this.props.env !== 'development',
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      autoplaySpeed: 6000,
      dots: true
    };
    return (
      <Slider {...settings} className="home-banner twic-view">
        {items.map((item, index) => {
          const bannerUrl = item.banner.url.replace('images.prismic.io', 'wakatoon.cdn.prismic.io');
          return (
            <div key={index}>
              <picture>
                <source media="(max-height: 414px)" srcSet={`${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=21:9/resize=800/${bannerUrl} 800w, ${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=21:9/resize=1000/${bannerUrl} 1000w, ${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=21:9/resize=1200/${bannerUrl} 1200w`}/>
                <source media="(max-width: 767px)" srcSet={`${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=1:1/resize=300/${bannerUrl} 300w, ${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=1:1/resize=500/${bannerUrl} 500w, ${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=1:1/resize=700/${bannerUrl} 900w`}/>
                <source media="(min-width: 992px)" srcSet={`${this.props.twic}/cover=21:9/resize=1000/${bannerUrl} 1000w, ${this.props.twic}/cover=21:9/resize=1500/${bannerUrl} 1500w, ${this.props.twic}/cover=21:9/resize=1900/${bannerUrl} 1900w`}/>
                <source media="(min-width: 768px)" srcSet={`${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=4:3/resize=800/${bannerUrl} 800w, ${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=4:3/resize=1000/${bannerUrl} 1000w, ${this.props.twic}/${item.focus ? `focus=${item.focus}p/` : ''}cover=4:3/resize=1200/${bannerUrl} 1200w`}/>
                <Image src={bannerUrl} alt={item.image_alt_attribute1} />
              </picture>
              <div className={Style.items}>
                <div className={Style.wrapper}>
                  <p style={{ backgroundImage: `url(${stamp.url})` }}>
                    {item.caption.text}
                    <span>{item.more_text.text}</span>
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.loaded
});

export default connect(mapStateToProps)(HomeBanner);